import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FlashDealCampaignControllerService } from '@soctrip/angular-advertising-service';
import { GlobalCatalogControllerService } from '@soctrip/angular-catalog-service';
import {
  CampaignBlockDTO as CampaignBlock,
  CampaignLayerDetailDTO as CampaignLayers,
  MarketingCampaignControllerService,
} from '@soctrip/angular-marketing-hub-service';
import { MarketingControllerService } from '@soctrip/angular-promotion-service';
import { AddressDirectoryControllerService } from '@soctrip/angular-shipment-service';
import { ShopControllerService } from '@soctrip/angular-shop-service';
import { StockProductControllerService } from '@soctrip/angular-stock-service';
import { DndDropEvent } from 'ngx-drag-drop';
import { catchError, forkJoin, map, of, switchMap } from 'rxjs';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import {
  BLOCK_TYPE,
  PREVIEW_DEVICE,
  PRODUCT_LIST_FILTER,
} from 'src/app/core/enum/marketing-builder';
import { Province } from 'src/app/core/models/interfaces/address';
import { Catalog } from 'src/app/core/models/interfaces/catalog';
import { FlashDealCampaign } from 'src/app/core/models/interfaces/flash-deal';
import {
  Block,
  ElementSelected,
  Layer,
  Media,
} from 'src/app/core/models/interfaces/marketing-builder';
import { FileService } from 'src/app/core/services/file.service';
import { randomUUID } from 'src/app/core/utils/random-UUID';
import { environment } from 'src/environments/environment';
import { MarketingData } from '../../components/header-builder/header-builder.component';

@Component({
  selector: 'app-landing-page-builder',
  templateUrl: './landing-page-builder.component.html',
  styleUrls: ['./landing-page-builder.component.scss'],
})
export class LandingPageBuilderComponent implements OnInit {
  readonly SoctripIcons = SoctripIcons;
  readonly BLOCK_TYPE = BLOCK_TYPE;
  readonly builderPrefix = 'landing-page-builder.';

  // LOCAL DATA
  previewDevice: string = PREVIEW_DEVICE.DESKTOP;
  mode: string = 'BUILDER';
  campaignId: string = '';
  layers: Layer[] = [
    {
      id: randomUUID(),
      code: 'ONE_ONE',
      textColor: '#101828',
      bgColor: '#FFFFFF',
      name: '',
      description: '',
      blocks: [],
      isNew: true,
    },
  ];
  elementSelected: ElementSelected = {
    scope: 'LAYER',
    layerIndex: 0,
    blockIndex: 0,
  };

  // SERVER DATA
  isLoading: boolean = false;
  layersData: CampaignLayers[] = [];
  categories: Catalog[] = [];

  marketing: MarketingData = {
    name: '',
    language: 'vn',
    module: 'SHOP',
    updatedAt: new Date(),
    link: '',
  };

  cities: Province[] = [];

  constructor(
    public fileService: FileService,
    private marketingCampaignService: MarketingCampaignControllerService,
    private route: ActivatedRoute,
    private flashDealCampaignService: FlashDealCampaignControllerService,
    private stockProductService: StockProductControllerService,
    private globalCatalogService: GlobalCatalogControllerService,
    private httpClient: HttpClient,
    private addressDirectoryService: AddressDirectoryControllerService,
    private shopService: ShopControllerService,
    private marketingService: MarketingControllerService,
  ) {}

  ngOnInit(): void {
    const id = this.route.snapshot.paramMap.get('id');
    if (id && id !== 'new') {
      this.isLoading = true;
      this.campaignId = id;
      forkJoin({
        category: this.globalCatalogService.globalCatalogsTreeGet(),
        marketing: this.marketingCampaignService.marketingCampaignsIdGet(id),
        address:
          this.addressDirectoryService.addressProvincesCountryCodeGet('vn'),
      })
        .pipe(
          switchMap(({ category, marketing, address }) => {
            this.marketing = {
              name: marketing.data?.title || '',
              language: marketing.data?.country_code || 'vn',
              module: marketing.data?.module_type || 'SHOP',
              updatedAt: marketing.data?.updated_at || new Date(),
              link: marketing.data?.link || '',
            };

            this.categories = category.data;
            this.cities = address.data;
            const layers = marketing?.data?.layers?.sort(
              (a, b) => (a.order || 0) - (b.order || 0),
            ) as CampaignLayers[];
            if (layers.length === 0)
              return of([
                {
                  id: randomUUID(),
                  code: 'ONE_ONE',
                  textColor: '#101828',
                  bgColor: '#FFFFFF',
                  name: '',
                  description: '',
                  blocks: [],
                  isNew: true,
                },
              ]);
            return this.convertLayersData(layers);
          }),
        )
        .subscribe({
          next: (res) => {
            this.isLoading = false;
            this.layers = [...res];
          },
        });
    }
  }

  convertLayersData(layouts: CampaignLayers[]) {
    return forkJoin(
      layouts.map((layout) => {
        const layerConvert: Layer = this.mapLayer(layout);
        const blocks = layout.blocks || [];
        if (blocks.length === 0) {
          return of([]).pipe(
            map((blocks) => ({ ...layerConvert, blocks: blocks })),
          );
        }

        return forkJoin(
          blocks.map((block) => {
            const {
              campaignIds,
              keywords,
              categoryIds,
              point,
              cities,
              shopIds,
              productIds,
              minPrice,
              maxPrice,
            } = this.getFilters(block.filter);
            const blockConvert: Block = this.mapBlock(block);
            const filters = block.filter
              ? encodeURIComponent(block.filter)
              : '';
            const voucherIds = block?.objects?.map((obj) => obj.object_id);
            return forkJoin({
              products:
                block.code === BLOCK_TYPE.PRODUCT_LIST
                  ? this.httpClient
                      .get<any>(
                        `${
                          environment.BE_URL
                        }stock/products/search/marketing-hub?filters=${filters}&page=0&size=${
                          (block.line || 0) * (block.items_per_line || 0)
                        }&sort=${encodeURIComponent(block.sort || '')}`,
                      )
                      .pipe(catchError(() => of(undefined)))
                  : of(undefined),
              promotions: campaignIds?.length
                ? this.flashDealCampaignService
                    .getCampaignListByIds(campaignIds)
                    .pipe(catchError(() => of(undefined)))
                : of(undefined),
              shops: shopIds?.length
                ? this.shopService
                    .shopsIdsGet(shopIds)
                    .pipe(catchError(() => of(undefined)))
                : of(undefined),
              specialProducts: productIds?.length
                ? this.stockProductService
                    .productsListGet(productIds)
                    .pipe(catchError(() => of(undefined)))
                : of(undefined),
              vouchers: voucherIds?.length
                ? this.marketingService
                    .findMultiVouchersByIds(
                      voucherIds.filter((id): id is string => id !== undefined),
                    )
                    .pipe(catchError(() => of(undefined)))
                : of(undefined),
            }).pipe(
              map(
                ({
                  products,
                  promotions,
                  shops,
                  specialProducts,
                  vouchers,
                }) => {
                  const filterItems: PRODUCT_LIST_FILTER[] = [];
                  const categories = this.mapIdsToCatalog(
                    categoryIds,
                    this.categories,
                  );

                  if (promotions?.data?.length) {
                    filterItems.push(PRODUCT_LIST_FILTER.PROMOTION);
                  }
                  if (keywords.length) {
                    filterItems.push(PRODUCT_LIST_FILTER.KEYWORD);
                  }
                  if (categories.length) {
                    filterItems.push(PRODUCT_LIST_FILTER.CATEGORY);
                  }
                  if (point) {
                    filterItems.push(PRODUCT_LIST_FILTER.PRODUCT_RATING);
                  }
                  if (cities.length) {
                    filterItems.push(PRODUCT_LIST_FILTER.CITY);
                  }
                  if (shops?.data?.length) {
                    filterItems.push(PRODUCT_LIST_FILTER.SHOP);
                  }
                  if (minPrice !== undefined || maxPrice !== undefined) {
                    filterItems.push(PRODUCT_LIST_FILTER.PRICE_RANGE);
                  }

                  return {
                    ...blockConvert,
                    filters: filterItems,
                    sorts: this.getSorts(block.sort),
                    products: products?.data?.data,
                    keywords: keywords,
                    product_ratings: point,
                    cities: cities.map((city) => ({
                      id: city.province_id,
                      name: city.province_name,
                    })),
                    shops: shops?.data?.map((shop: any) => ({
                      id: shop.id,
                      name: shop.name,
                      avatar: shop.avatar_id,
                    })),
                    categories: categories,
                    minPrice: minPrice,
                    maxPrice: maxPrice,
                    promotions: promotions?.data?.map(
                      (campaign: FlashDealCampaign) => ({
                        id: campaign?.id || '',
                        title: campaign?.title || '',
                        thumbnail: campaign?.filter_thumbnail || '',
                      }),
                    ),
                    specific_products:
                      specialProducts?.data?.map((product: any) => ({
                        id: product.id,
                        name: product.name,
                        avatar: product.avatar.id,
                      })) || [],
                    isSpecificProduct: specialProducts?.data?.length > 0,
                    vouchers: vouchers?.data as any[],
                  };
                },
              ),
            );
          }),
        ).pipe(map((blocks) => ({ ...layerConvert, blocks: blocks })));
      }),
    );
  }

  private mapLayer(layer: CampaignLayers): Layer {
    return {
      id: layer.id || '',
      code: layer.code || 'ONE_ONE',
      name: layer.title || '',
      textColor: layer.color || '#000000',
      bgColor: layer.background_color || '#ffffff',
      description: layer.description || '',
      isNew: false,
      blocks: [],
    };
  }

  private mapBlock(block: CampaignBlock): Block {
    return {
      flex: block.row || 1,
      type: block.code || '',
      medias: this.mapMedia(block.media),
      text: block.text || '',
      description: block.description || '',
      isStick: block.position === 'STICKY' ? true : false,
      module: block.module || 'SHOP',
      line: block.line || 1,
      itemPerLine: block.items_per_line || 2,
      isSpecificProduct: false,
      specific_products: [],
      sorts: [],
      filters: [],
      vouchers: [],
    };
  }

  private mapMedia(media?: any[]): Media[] {
    return (
      media?.map((item) => ({
        isLoading: false,
        media1: item.media_desktop_id
          ? {
              id: item.media_desktop_id,
              name: item.desktop_name || '',
              size: item.desktop_size || '',
            }
          : undefined,
        media2: item.media_mobile_id
          ? {
              id: item.media_mobile_id,
              name: item.mobile_name || '',
              size: item.mobile_size || '',
            }
          : undefined,
        link1: item.desktop_link || '',
        link2: item.mobile_link || '',
        alignment: '',
        isOpenNewTab: item.is_open_in_new_tab,
        reference_id: item.reference_id,
      })) || []
    );
  }

  private getFilters(filter?: string) {
    let campaignIds: string[] = [];
    let keywords: string[] = [];
    let categoryIds: string[] = [];
    let point: number = 0;
    let cities: Province[] = [];
    let shopIds: string[] = [];
    let productIds: string[] = [];
    let minPrice: number | undefined = undefined;
    let maxPrice: number | undefined = undefined;

    const filters = filter?.split(',');

    filters?.forEach((item) => {
      if (item.includes('flash_deal_docs.id==')) {
        campaignIds = item.replace('flash_deal_docs.id==', '').split('|');
      }

      if (item.includes('name@=')) {
        keywords = item.replace('name@=', '').split('|');
      }

      if (item.includes('str_catalog@=')) {
        categoryIds = item.replace('str_catalog@=', '').split('|');
      }

      if (item.includes('point>=')) {
        point = Number(item.replace('point>=', ''));
      }

      if (item.includes('str_address@=')) {
        cities = item
          .replace('str_address@=', '')
          .split('|')
          .map(
            (item) =>
              this.cities.find((city) => {
                return city.province_name === item;
              }) as Province,
          )
          .filter((item) => item !== undefined);
      }

      if (item.includes('shop_id==')) {
        shopIds = item.replace('shop_id==', '').split('|');
      }

      if (item.includes('id==')) {
        productIds = item.replace('id==', '').split('|');
      }

      if (item.includes('usd_price>=')) {
        minPrice = Number(item.replace('usd_price>=', ''));
      }

      if (item.includes('usd_price<=')) {
        maxPrice = Number(item.replace('usd_price<=', ''));
      }
    });

    return {
      campaignIds,
      keywords,
      categoryIds,
      point,
      cities,
      shopIds,
      productIds,
      minPrice,
      maxPrice,
    };
  }

  private getSorts(sorts?: string): Array<{ key: string; value: number }> {
    if (!sorts) return [];

    return sorts.split(',').map((sort) => {
      const isDescending = sort.startsWith('-');
      const key = sort.replace('-', '').trim();

      return {
        key: key.trim(),
        value: isDescending ? -1 : 1,
      };
    });
  }

  onDropBlock(event: DndDropEvent, layerIndex: number, blockIndex: number) {
    const data = event.data;
    if (data.tab !== 'BLOCK') return;

    const type = data.data.value;

    switch (type) {
      case BLOCK_TYPE.IMAGE: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          medias: [
            {
              media1: undefined,
              media2: undefined,
              alignment: 'LEFT',
              isOpenNewTab: false,
              link1: '',
            },
          ],
        };
        break;
      }
      case BLOCK_TYPE.TEXT: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          text: '',
          description: '',
        };
        break;
      }
      case BLOCK_TYPE.SLIDE: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          medias: [
            {
              isLoading: false,
              link1: '',
              link2: '',
            },
          ],
        };
        break;
      }
      case BLOCK_TYPE.VIDEO: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          medias: [
            {
              media1: undefined,
              isLoading: false,
              alignment: 'LEFT',
              link2: '',
            },
          ],
        };
        break;
      }
      case BLOCK_TYPE.TAB: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          medias: [],
        };
        break;
      }
      case BLOCK_TYPE.PRODUCT_LIST: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          line: 1,
          itemPerLine: 4,
        };
        break;
      }
      case BLOCK_TYPE.VOUCHER: {
        this.layers[layerIndex].blocks[blockIndex] = {
          ...this.layers[layerIndex].blocks[blockIndex],
          line: 1,
          itemPerLine: 2,
        };
        break;
      }
      default: {
        return;
      }
    }

    this.layers[layerIndex].blocks[blockIndex].type = type;
    this.layers = [...this.layers];
  }

  onDropLayout(event: DndDropEvent, index: number) {
    const data = event.data;

    if (data.tab !== 'LAYOUT') return;

    const layout: number[] = data.data.value;
    this.layers[index].blocks = layout.map((item) => ({
      flex: item,
      type: '',
      medias: [],
      text: '',
      description: '',
      isStick: false,
      module: '',
      line: 1,
      itemPerLine: 4,
      sorts: [],
      filters: [],
      vouchers: [],
      specific_products: [],
      isSpecificProduct: false,
    }));

    this.layers = [...this.layers];
  }

  onAddLayer(event: Event, index: number) {
    event.stopPropagation();

    this.layers.splice(index + 1, 0, {
      id: randomUUID(),
      code: 'ONE_ONE',
      textColor: '#101828',
      bgColor: '#FFFFFF',
      name: '',
      description: '',
      blocks: [],
      isNew: true,
    });
    this.layers = [...this.layers];

    this.elementSelected = {
      scope: 'LAYER',
      layerIndex: index + 1,
      blockIndex: 0,
    };
  }

  onClickLayer(event: Event, index: number) {
    event.stopPropagation();
    this.elementSelected = {
      scope: 'LAYER',
      layerIndex: index,
      blockIndex: 0,
    };
  }

  onClickBlock(event: Event, layerIndex: number, blockIndex: number) {
    event.stopPropagation();
    this.elementSelected = {
      scope: 'BLOCK',
      layerIndex,
      blockIndex,
    };
  }

  onDropLayer(event: CdkDragDrop<Layer[]>) {
    moveItemInArray(this.layers, event.previousIndex, event.currentIndex);
    this.layers = [...this.layers];

    this.elementSelected = {
      ...this.elementSelected,
      layerIndex: event.currentIndex,
    };
  }

  onDeleteLayer(index: number) {
    if (this.layers.length === 1) {
      this.layers = [
        {
          id: randomUUID(),
          code: 'ONE_ONE',
          textColor: '#101828',
          bgColor: '#FFFFFF',
          name: '',
          description: '',
          blocks: [],
          isNew: true,
        },
      ];

      this.elementSelected = {
        scope: 'LAYER',
        layerIndex: 0,
        blockIndex: 0,
      };
    } else {
      this.layers.splice(index, 1);

      const newLayerIndex = Math.max(0, index - 1);
      this.elementSelected = {
        scope: 'LAYER',
        layerIndex: newLayerIndex,
        blockIndex: 0,
      };
    }

    this.layers = [...this.layers];
  }

  onDeleteBlock(layerIndex: number, blockIndex: number) {
    const layer = this.layers[layerIndex];

    if (!layer || layer.blocks.length === 0) {
      return;
    }
    const defaultBlock: Block = {
      flex: layer.blocks[blockIndex].flex ?? 1,
      type: '',
      medias: [],
      text: '',
      description: '',
      isStick: false,
      module: '',
      line: 1,
      itemPerLine: 4,
      sorts: [],
      filters: [],
      vouchers: [],
      isSpecificProduct: false,
      specific_products: [],
    };
    layer.blocks[blockIndex] = { ...defaultBlock };
  }

  onCloneLayer(index: number) {
    const newLayer = JSON.parse(JSON.stringify(this.layers[index]));
    this.layers.splice(index + 1, 0, {
      ...newLayer,
      id: randomUUID(),
      isNew: true,
    });

    this.layers = [...this.layers];
  }

  generateArray(length: number) {
    return Array(length).fill(1);
  }

  getGridTemplate(row: number) {
    return `repeat(${row}, minmax(0, 1fr))`;
  }

  findCatalogById(id: string, catalogs: Catalog[]): Catalog | null {
    for (const catalog of catalogs) {
      if (catalog.id === id) {
        return catalog;
      }
      if (catalog.sub_catalogs && catalog.sub_catalogs.length > 0) {
        const result = this.findCatalogById(id, catalog.sub_catalogs);
        if (result) return result;
      }
    }
    return null;
  }

  mapIdsToCatalog(ids: string[], catalogs: Catalog[]) {
    // Hàm đệ quy để tìm catalog và các sub-catalogs

    return ids.map((id) => {
      const catalog = this.findCatalogById(id, catalogs);
      if (!catalog) {
        throw new Error(`Catalog with ID ${id} not found`);
      }
      return {
        id: catalog.id,
        depth: catalog.depth,
        image: catalog.image,
        name: catalog.name,
        sub_catalogs: catalog.sub_catalogs, // Giữ nguyên các sub_catalogs
      };
    });
  }

  formatDiscountMinSpend(voucher: any): string {
    const discountRange = voucher?.voucher_discount_range?.[0];
    if (!discountRange) return '';

    const currency = discountRange?.currency || '$';
    return `Min spend ${discountRange.min_order_price}${currency}`;
  }

  getMaxWidth(flex: number, blocks: Block[]): string {
    if (blocks.length <= 1) {
      return '100%';
    }

    const totalFlex = blocks.reduce(
      (sum: number, block: Block) => sum + block.flex,
      0,
    );

    return `calc(${(100 / totalFlex) * flex}% - ${16 / (blocks.length - 1)}px)`;
  }
}
