import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { GlobalCatalogControllerService } from '@soctrip/angular-catalog-service';
import { Subject, debounceTime } from 'rxjs';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { BlockEntityData } from 'src/app/core/models/interfaces/marketing-builder';
import { ProductDetails } from 'src/app/core/models/interfaces/product';
import { FileService } from 'src/app/core/services/file.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-product-modal',
  templateUrl: './add-product-modal.component.html',
  styleUrls: ['./add-product-modal.component.scss'],
})
export class AddProductModalComponent implements OnInit {
  protected readonly SoctripIcons = SoctripIcons;
  @Input({ required: true }) isModal = false;
  @Input({ required: true }) selectedProducts: BlockEntityData[] = [];

  @Output() isModalChange = new EventEmitter<boolean>();
  @Output() selectedProductsChange = new EventEmitter<any[]>();
  @Output() totalProduct = new EventEmitter<number>();

  _products: ProductDetails[] = [];
  _totalProduct = 0;
  isFetching = false;
  isFetched = false;

  RATINGS = [1, 2, 3, 4, 5];
  rating: number | null = null;
  _categories: any[] = [];
  categories: any[] = [];
  pagination = {
    rows: 10,
    page: 0,
  };
  keyword = '';
  keywordChanged = new Subject<string>();
  language: string = localStorage.getItem('lang') || 'en';

  constructor(
    private globalCatalogService: GlobalCatalogControllerService,
    public fileService: FileService,
    private httpClient: HttpClient,
  ) {}

  ngOnInit() {
    this.keywordChanged.pipe(debounceTime(800)).subscribe({
      next: (keyword) => {
        this.keyword = keyword;
        this._fetchProducts(true);
      },
    });
    this._fetchCategories();
    this._fetchProducts(true);
  }

  _fetchCategories() {
    this.globalCatalogService.globalCatalogsTreeGet(this.language).subscribe({
      next: (res) => {
        if (res?.data) {
          this._categories = this.convertTreeNode(res);
        }
      },
    });
  }

  convertTreeNode(res: any) {
    const flatData: any[] = res.data;
    const buildTree = (
      flatNodes: any[],
      parentId: string | null = null,
    ): any[] => {
      const tree: any[] = [];

      for (const node of flatNodes) {
        if (node.parent_id === parentId) {
          const newNode: any = {
            key: node.id,
            label: node.name,
          };
          if (node.sub_catalogs && node.sub_catalogs.length > 0) {
            newNode.children = buildTree(node.sub_catalogs, node.id);
          }
          tree.push(newNode);
        }
      }
      return tree;
    };
    const findRoots = (flatNodes: any[]): any[] => {
      const parentIds = new Set(flatNodes.map((node) => node.parent_id));
      return flatNodes.filter((node) => !parentIds.has(node.id));
    };

    const roots = findRoots(flatData);
    const treeData = buildTree(roots, null);
    return treeData;
  }

  _fetchProducts(resetPage: boolean) {
    if (resetPage) {
      this.onResetPagination();
    }
    if (this.isFetched) {
      this.isFetching = true;
    }

    const catalog_ids = this.categories
      ?.map((category) => category.key)
      .join('|');

    const filters = [
      catalog_ids ? `catalog_ids==${catalog_ids}` : '',
      this.keyword ? `name@=${this.keyword}` : '',
      this.rating ? `point>=${this.rating}` : '',
    ]
      .filter((i) => i)
      .join(',');

    this.httpClient
      .get(
        `${environment.BE_URL}stock/products/search/marketing-hub?filters=${
          filters ? encodeURIComponent(filters) : ''
        }&pageNum=${this.pagination.page}&pageSize=${this.pagination.rows}`,
      )
      .subscribe({
        next: (res: any) => {
          this._products = res.data.data;
          this._totalProduct = res.data.totalElement;
          this.totalProduct.emit(this._totalProduct);
          if (this.isFetched) {
            this.isFetching = false;
          } else {
            this.isFetched = true;
          }
        },
        error: () => {
          if (this.isFetched) {
            this.isFetching = false;
          } else {
            this.isFetched = true;
          }
        },
      });
  }

  onRemoveCategory(event: any, index: number) {
    event.stopPropagation();
    this.categories.splice(index, 1);
    this._fetchProducts(true);
  }

  onResetPagination() {
    this.pagination = {
      page: 0,
      rows: 10,
    };
  }

  setIsModal(isModal: boolean) {
    this.isModal = isModal;
    this.isModalChange.emit(this.isModal);
  }

  onChangeSelectedProducts(e: any) {
    this.selectedProducts = e;
  }

  onAddProducts() {
    this.setIsModal(false);
    this.selectedProductsChange.emit(this.selectedProducts);
  }

  onCancelAddProduct() {
    this.setIsModal(false);
  }
}
