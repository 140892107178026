import { HttpClient } from '@angular/common/http';
import { Component, Input, OnChanges, OnInit } from '@angular/core';
import {
  FlashDealCampaign,
  FlashDealCampaignControllerService,
} from '@soctrip/angular-advertising-service';
import { GlobalCatalogControllerService } from '@soctrip/angular-catalog-service';
import { AddressDirectoryControllerService } from '@soctrip/angular-shipment-service';
import { InputNumberInputEvent } from 'primeng/inputnumber';
import {
  FILTER_TAB,
  PRODUCT_LIST_FILTERS_BY,
  SORT_ITEMS,
  SORT_PRODUCTS,
  STARS,
} from 'src/app/core/constants/marketing-builder';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import {
  FILTER_TAB_ENUM,
  PRODUCT_LIST_FILTER,
} from 'src/app/core/enum/marketing-builder';
import { Province } from 'src/app/core/models/interfaces/address';
import { Catalog } from 'src/app/core/models/interfaces/catalog';
import {
  ElementSelected,
  Layer,
} from 'src/app/core/models/interfaces/marketing-builder';
import { ProductDetails } from 'src/app/core/models/interfaces/product';
import { Shop } from 'src/app/core/models/interfaces/shop';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-setup-products',
  templateUrl: './setup-products.component.html',
  styleUrls: ['./setup-products.component.scss'],
})
export class SetupProductsComponent implements OnInit, OnChanges {
  @Input() elementSelected: ElementSelected;
  @Input() layers: Layer[] = [];

  readonly SoctripIcons = SoctripIcons;
  readonly STARS = STARS;
  readonly filtersBy = PRODUCT_LIST_FILTERS_BY;
  readonly PRODUCT_LIST_FILTER = PRODUCT_LIST_FILTER;
  readonly SORT_PRODUCTS = SORT_PRODUCTS;
  readonly builderPrefix = 'landing-page-builder.';
  readonly filterTab = FILTER_TAB;
  readonly filterTabEnum = FILTER_TAB_ENUM;

  isCounting: boolean = false;
  isModalFilter: boolean = false;
  isModalPromotion: boolean = false;
  isModalSort: boolean = false;
  isModalCategory: boolean = false;
  isModalProductRating: boolean = false;
  isModalCity: boolean = false;
  isModalShop: boolean = false;
  isModalProduct: boolean = false;

  categoriesGlobalList: Array<Catalog[]> = [];
  cities: Province[] = [];
  shops: Shop[] = [];
  keyword: string = '';

  promotionPrograms: FlashDealCampaign[] = [];
  products: ProductDetails[] = [];
  currentFilter = PRODUCT_LIST_FILTER;

  constructor(
    private flashDealCampaignService: FlashDealCampaignControllerService,
    private globalCatalogService: GlobalCatalogControllerService,
    private addressDirectoryService: AddressDirectoryControllerService,
    private httpClient: HttpClient,
  ) {}

  get blockData() {
    return this.layers[this.elementSelected.layerIndex].blocks[
      this.elementSelected.blockIndex
    ];
  }

  ngOnInit() {
    this.flashDealCampaignService.filterQuickSearch().subscribe((res) => {
      const data: FlashDealCampaign[] = res.data.data;

      this.promotionPrograms = data;
    });

    this.globalCatalogService.globalCatalogsTreeGet().subscribe({
      next: (response) => {
        this.categoriesGlobalList = [response.data];
      },
    });

    this.addressDirectoryService
      .addressProvincesCountryCodeGet('vn')
      .subscribe({
        next: (res) => {
          this.cities = res.data;
        },
      });

    this.onCountProduct();
  }

  ngOnChanges(): void {
    this.keyword = '';
  }

  onLineChange(event: InputNumberInputEvent) {
    const inputValue = +event.value;
    if (inputValue < 1) {
      this.blockData.line = 1;
    } else if (inputValue > 5) {
      this.blockData.line = 5;
    }
  }

  onItemPerLineChange(event: InputNumberInputEvent) {
    const inputValue = +event.value;
    if (inputValue < 1) {
      this.blockData.itemPerLine = 1;
    } else if (inputValue > 5) {
      this.blockData.itemPerLine = 5;
    }
  }

  // FILTERS
  onToggleFilter() {
    this.isModalFilter = true;
  }

  onAddFilter(filter: PRODUCT_LIST_FILTER) {
    if (this.isDisableFilter(filter)) return;

    this.isModalFilter = false;
    switch (filter) {
      case PRODUCT_LIST_FILTER.PROMOTION: {
        this.blockData.promotions = [];
        break;
      }
      case PRODUCT_LIST_FILTER.KEYWORD: {
        this.blockData.keywords = [];
        break;
      }
      case PRODUCT_LIST_FILTER.CATEGORY: {
        this.blockData.categories = [];
        break;
      }
      case PRODUCT_LIST_FILTER.PRODUCT_RATING: {
        this.blockData.product_ratings = undefined;
        break;
      }
      case PRODUCT_LIST_FILTER.CITY: {
        this.blockData.cities = [];
        break;
      }
      case PRODUCT_LIST_FILTER.SHOP: {
        this.blockData.shops = [];
        break;
      }
      case PRODUCT_LIST_FILTER.PRICE_RANGE: {
        this.blockData.minPrice = 0;
        this.blockData.maxPrice = 0;
        break;
      }
    }

    this.blockData.filters.push(filter);
  }

  isDisableFilter(filter: PRODUCT_LIST_FILTER) {
    return this.blockData.filters.includes(filter);
  }

  onDeleteItem(filter: string, itemId: string, index: number) {
    switch (filter) {
      case PRODUCT_LIST_FILTER.PROMOTION: {
        if (this.blockData.promotions) {
          const currentPromotions = [...this.blockData.promotions].filter(
            (item) => item.id !== itemId,
          );
          this.blockData.promotions = [...currentPromotions];
        }
        break;
      }
      case PRODUCT_LIST_FILTER.KEYWORD: {
        if (this.blockData.keywords) {
          this.blockData.keywords.splice(index, 1);
        }
        break;
      }
      case PRODUCT_LIST_FILTER.CATEGORY: {
        if (this.blockData.categories) {
          const categories = [...this.blockData.categories].filter(
            (category) => category.id !== itemId,
          );

          this.blockData.categories = [...categories];
        }
        break;
      }
      case PRODUCT_LIST_FILTER.PRODUCT_RATING: {
        if (this.blockData.product_ratings) {
          // this.blockData.product_ratings = [
          //   ...this.blockData.product_ratings,
          // ].filter((rating) => rating !== index);
          this.blockData.product_ratings = undefined;
        }
        break;
      }
      case PRODUCT_LIST_FILTER.CITY: {
        if (this.blockData.cities) {
          this.blockData.cities = [...this.blockData.cities].filter(
            (city) => city.id !== itemId,
          );
        }
        break;
      }
      case PRODUCT_LIST_FILTER.SHOP: {
        if (this.blockData.shops) {
          this.blockData.shops = [...this.blockData.shops].filter(
            (shop) => shop.id !== itemId,
          );
        }
        break;
      }
    }
  }

  onDeleteProduct(itemId: string) {
    if (this.blockData.specific_products) {
      this.blockData.specific_products = [
        ...this.blockData.specific_products,
      ].filter((product) => product.id !== itemId);
    }
  }

  //Promotion
  onToggleProgram() {
    this.isModalPromotion = true;
  }

  // Keyword
  onAddKeyword(event: Event) {
    const target = event.target as HTMLInputElement;

    if (!target.value) return;

    if (this.blockData.keywords) {
      this.blockData.keywords.push(target.value);
    } else {
      this.blockData.keywords = [target.value];
    }
    this.keyword = '';
  }

  // Category
  isCategoryAdded(id: string) {
    return this.blockData.categories?.some((category) => category.id === id);
  }

  hoverCategory(event: MouseEvent, category: Catalog) {
    event.stopPropagation();
    this.categoriesGlobalList.splice(category.depth + 1);

    if (category.sub_catalogs)
      this.categoriesGlobalList.push(category.sub_catalogs);
  }

  selectCategory(event: MouseEvent, category: Catalog) {
    if (this.isCategoryAdded(category.id)) {
      return;
    }
    event.stopPropagation();

    this.blockData.categories?.push(category);

    this.isModalCategory = false;
  }

  // SORTS
  onToggleAddSort() {
    this.isModalSort = true;
  }

  onAddSort(sort: string, scope: string) {
    if (this.isDisableSort(sort)) return;

    this.isModalSort = false;
    const value = SORT_ITEMS.find((item) => item.scope === scope)?.value || 1;
    this.blockData.sorts.push({
      key: sort,
      value: value,
    });
  }

  onDeleteSort(key: string) {
    const sorts = this.blockData.sorts.filter((item) => item.key !== key);
    this.blockData.sorts = [...sorts];
  }

  onMoveUpSort(index: number) {
    const temp = this.blockData.sorts[index];

    this.blockData.sorts[index] = { ...this.blockData.sorts[index - 1] };
    this.blockData.sorts[index - 1] = { ...temp };
  }

  onMoveDownSort(index: number) {
    const temp = this.blockData.sorts[index];

    this.blockData.sorts[index] = { ...this.blockData.sorts[index + 1] };
    this.blockData.sorts[index + 1] = { ...temp };
  }

  getSortValueByKey(key: string) {
    const scope = SORT_PRODUCTS.find((item) => item.value === key)?.scope;
    return SORT_ITEMS.filter((item) => item.scope === scope);
  }

  isDisabledSortKey(key: string) {
    return this.blockData.sorts.some((sort) => sort.key === key);
  }

  isDisableSort(sortValue: string) {
    return this.blockData.sorts.some((item) => item.key === sortValue);
  }

  onCountProduct() {
    const layerIndex = this.elementSelected.layerIndex;
    const blockIndex = this.elementSelected.blockIndex;

    const {
      line,
      itemPerLine,
      promotions,
      keywords,
      categories,
      sorts,
      product_ratings,
      cities,
      shops,
      specific_products,
      isSpecificProduct,
      minPrice,
      maxPrice,
    } = this.blockData;

    const productFilters: string[] = [];
    const productSorts: string[] = [];

    if (isSpecificProduct) {
      if (specific_products?.length) {
        productFilters.push(
          `id==${specific_products.map((product) => product.id).join('|')}`,
        );
      }
    } else {
      if (minPrice) {
        productFilters.push(`usd_price>=${minPrice}`);
      }

      if (maxPrice) {
        productFilters.push(`usd_price<=${maxPrice}`);
      }

      if (promotions?.length) {
        productFilters.push(
          `flash_deal_docs.id==${promotions
            .map((promotion) => promotion.id)
            .join('|')}`,
        );
      }

      if (keywords?.length) {
        productFilters.push(`name@=${keywords.join('|')}`);
      }

      if (categories?.length) {
        productFilters.push(
          `str_catalog@=${categories.map((category) => category.id).join('|')}`,
        );
      }

      if (product_ratings) {
        productFilters.push(`point>=${product_ratings}`);
      }

      if (cities?.length) {
        productFilters.push(
          `str_address@=${cities.map((city) => city.name).join('|')}`,
        );
      }

      if (shops?.length) {
        productFilters.push(
          `shop_id==${shops.map((shop) => shop.id).join('|')}`,
        );
      }
    }

    if (sorts?.length) {
      sorts.forEach((item) => {
        productSorts.push(`${item.value > 0 ? '' : '-'}${item.key}`);
      });
    }

    const filters = encodeURIComponent(productFilters.join(','));
    this.isCounting = true;
    this.httpClient
      .get<any>(
        `${
          environment.BE_URL
        }stock/products/search/marketing-hub?filters=${filters}&page=0&size=${
          line * itemPerLine
        }&sort=${encodeURIComponent(productSorts.join(','))}`,
      )

      .subscribe({
        next: (res) => {
          this.isCounting = false;
          this.products = res.data.data;
          this.layers[layerIndex].blocks[blockIndex].products = res.data.data;
          this.layers[layerIndex].blocks[blockIndex].totalProducts =
            res.data.totalElement;
        },
        error: () => {
          this.isCounting = false;
        },
      });
  }

  onFilterTypeChange(value: FILTER_TAB_ENUM) {
    this.blockData.isSpecificProduct =
      value === FILTER_TAB_ENUM.SPECIFIC_PRODUCTS;
  }
}
