import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-landing-page-footer',
  templateUrl: './landing-page-footer.component.html',
  styleUrls: ['./landing-page-footer.component.scss'],
})
export class LandingPageFooter implements OnInit {
  about: Array<{ label: string; link: string }> = [
    {
      label: 'footer.about-data.about-us',
      link: `${environment.HOME_DOMAIN_URL}/help-center/about-us`,
    },
    {
      label: 'footer.about-data.condition',
      link: `${environment.HOME_DOMAIN_URL}/help-center/term`,
    },
    {
      label: 'footer.about-data.privacy',
      link: `${environment.HOME_DOMAIN_URL}/help-center/privacy`,
    },
    {
      label: 'footer.about-data.security',
      link: `${environment.HOME_DOMAIN_URL}/help-center/privacy`,
    },
    {
      label: 'footer.about-data.regulations',
      link: `${environment.HOME_DOMAIN_URL}/help-center/operation-rule`,
    },
    {
      label: 'footer.about-data.faq',
      link: `${environment.HOME_DOMAIN_URL}/help-center/send-us-feedback`,
    },
  ];
  booking: Array<{ label: string; link: string }> = [
    {
      label: 'footer.booking-data.flight',
      link: `${environment.FLIGHT_BETA_DOMAIN_URL}/booking/flight`,
    },
    {
      label: 'footer.booking-data.hotel',
      link: environment.HOTEL_BETA_DOMAIN_URL,
    },
    {
      label: 'footer.booking-data.car',
      link: `${environment.BOOKING_CAR_BETA_DOMAIN_URL}/booking/car`,
    },
    {
      label: 'footer.booking-data.tour',
      link: environment.TOUR_BETA_DOMAIN_URL,
    },
    {
      label: 'footer.booking-data.restaurant',
      link: environment.FOOD_DOMAIN_URL,
    },
    {
      label: 'footer.booking-data.shop',
      link: environment.ECOMMERCE_DOMAIN_URL,
    },
  ];
  otherServices: Array<{ label: string; link: string }> = [
    {
      label: 'footer.order-data.partner',
      link: `${environment.ECOMMERCE_DOMAIN_URL}/shopping/request-open-shop`,
    },
    {
      label: 'footer.order-data.program',
      link: `${environment.HOME_DOMAIN_URL}/help-center/about-us`,
    },
    {
      label: 'footer.order-data.feedback',
      link: `${environment.HOME_DOMAIN_URL}/help-center/send-us-feedback`,
    },
  ];
  contact = [
    {
      origin: 'US',
      phone: '+1 402 580 7168',
      mail: `contact.ecommerce.us@${environment.ECOMMERCE_APP_TITLE?.toLowerCase()}.com`,
      address: '5104 N Orange Blossom Trl, Orlando, FL 32810, United States',
    },
    {
      origin: 'Vietnam',
      phone: '+84 1900 571248',
      mail: `contact.ecommerce.vn@${environment.ECOMMERCE_APP_TITLE?.toLowerCase()}.com`,
      address:
        '400/8B-400/10, Ung Văn Khiêm, Phường 25, Quận Bình Thạnh, TP.HCM, Việt Nam',
    },
    {
      origin: 'India',
      phone: '+91 33 4802 5175',
      mail: `contact.ecommerce.in@${environment.ECOMMERCE_APP_TITLE?.toLowerCase()}.com`,
      address:
        '9th Floor, Aurora Waterfront, Unit No 902, Plot 34/1, GN Block, Kolkata, West Bengal 700091, India',
    },
  ];
  region = 'VN';
  allRightBy = environment.ECOMMERCE_APP_TITLE;

  constructor(private http: HttpClient) {}

  ngOnInit(): void {
    this.getRegion();
  }

  getRegion(): void {
    this.http.get(`${environment.BE_URL}regional/me`).subscribe({
      next: (res: any) => {
        if (res?.data?.data?.length) {
          this.region = res.data.data[0].country_code
            ? res.data.data[0].country_code
            : 'VN';
        }
      },
    });
  }

  clickItem(link: string) {
    if (link) window.open(link, '_blank');
  }

  call(phone: string): void {
    window.open(`tel:${phone}`, '_self');
  }

  email(mail: string): void {
    window.open(`mailto:${mail}`, '_self');
  }
}
