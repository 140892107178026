<div
  *ngIf="isUpdating"
  class="fixed inset-0 bg-black/20 z-[9999] flex items-center justify-center"
>
  <p-progressSpinner></p-progressSpinner>
</div>

<header
  class="fixed z-50 top-0 left-0 right-0 flex justify-between items-center px-6 py-3 bg-white border-b"
>
  <div class="flex items-center gap-3">
    <app-button
      styleClass="w-9"
      variant="outline-secondary"
      [icon]="SoctripIcons.CHEVRON_LEFT + ' text-xl'"
      (onClick)="onBack()"
    ></app-button>

    <input
      *ngIf="isBuilder; else previewMarketingLinkTemplate"
      pInputText
      [(ngModel)]="marketing.name"
      (ngModelChange)="onMarketingNameChange($event)"
      class="h-9 w-[300px]"
      placeholder="{{ builderPrefix + 'enter-marketing-name' | translate }}"
      [ngClass]="onCheckValidName()"
    />

    <ng-template #previewMarketingLinkTemplate>
      <div
        class="relative h-9 w-[400px] rounded-lg border border-gray-300 py-2 px-3 overflow-hidden flex items-center justify-between"
      >
        <div
          class="text-sm whitespace-nowrap overflow-ellipsis overflow-hidden w-full"
        >
          <span pTooltip="{{ marketing.link }}" tooltipPosition="bottom">
            {{ marketing.link }}
          </span>
        </div>
        <i
          class="{{
            SoctripIcons.COPY_03
          }} text-xl text-gray-500 cursor-pointer"
          (click)="onCopyLink(marketing.link)"
        ></i>
      </div>
    </ng-template>

    <p-dropdown
      *ngIf="isBuilder"
      appendTo="body"
      styleClass="h-9 w-[200px] flex items-center"
      [options]="languages"
      optionLabel="name"
      optionValue="code"
      [(ngModel)]="marketing.language"
      (ngModelChange)="onLanguageChange($event)"
    ></p-dropdown>

    <!-- Modules -->
    <p-dropdown
      *ngIf="isBuilder"
      appendTo="body"
      styleClass="h-9 w-[200px] flex items-center"
      [options]="modules"
      optionLabel="name"
      optionValue="value"
      [(ngModel)]="marketing.module"
      (ngModelChange)="onModuleChange($event)"
    ></p-dropdown>
  </div>

  <div
    class="flex items-center gap-[2px] h-9 p-[2px] rounded-lg bg-gray-100"
    *ngIf="isPreview"
  >
    <div
      *ngFor="let DEVICE of DEVICES"
      class="px-3 flex h-8 items-center gap-2 rounded-lg cursor-pointer"
      (click)="onChangeDevice(DEVICE.value)"
      [ngClass]="
        DEVICE.value === previewDevice
          ? 'bg-white text-blue-600'
          : 'hover:text-blue-600'
      "
    >
      <i class="{{ DEVICE.icon }}"></i>
      <span class="font-semibold text-sm">{{
        builderPrefix + DEVICE.title | lowercase | translate
      }}</span>
    </div>
  </div>

  <div class="flex items-center gap-3">
    <ng-container *ngIf="mode === 'BUILDER'">
      <span class="text-sm"
        >{{ "common.updated-at" | translate }}:
        {{ marketing.updatedAt | date: "MM/dd/yyyy hh:mm aaa" }}</span
      >
      <app-button
        variant="outline-secondary"
        [label]="builderPrefix + 'preview' | translate"
        (onClick)="onPreview()"
      ></app-button>
    </ng-container>

    <app-button
      variant="outline-secondary"
      [label]="builderPrefix + 'save-draft' | translate"
      (onClick)="onSave(false)"
    ></app-button>
    <app-button
      [label]="builderPrefix + 'save-publish' | translate"
      (onClick)="onSave(true)"
    ></app-button>
  </div>
</header>
