<div
  class="border-gray-200 bg-white"
  [ngClass]="isDesktop ? 'border-b' : 'border'"
>
  <div
    class="h-16 flex items-center justify-between mx-auto"
    [ngClass]="isDesktop ? 'max-w-[1200px] px-8' : 'max-w-[390px] px-4'"
  >
    <div class="flex items-center gap-2">
      <div *ngIf="isMobile" class="h-10 w-10 flex items-center justify-center">
        <i class="{{ SoctripIcons.MENU_01 }} text-2xl"></i>
      </div>

      <img
        class="mr-2"
        [ngClass]="isDesktop ? 'min-w-[124px]' : ''"
        [src]="
          isDesktop
            ? '/assets/imgs/logo/soctrip.svg'
            : '/assets/imgs/logo/logo.svg'
        "
        alt="Soctrip"
      />
    </div>

    <div
      *ngIf="isDesktop"
      class="flex-1 flex items-center justify-center gap-2"
    >
      <!-- HOME -->
      <div
        class="flex-1 max-w-[140px] flex items-center justify-center gap-2 text-gray-500"
      >
        <img src="/assets/icons/header/home.svg" alt="Home" />
        <span class="text-sm font-medium">{{
          "landing-page-builder.home" | translate
        }}</span>
      </div>

      <!-- BOOKING -->
      <div
        class="flex-1 max-w-[140px] flex items-center justify-center gap-2 text-gray-500"
      >
        <img src="/assets/icons/header/booking.svg" alt="Booking" />
        <span class="text-sm font-medium">{{
          "landing-page-builder.booking" | translate
        }}</span>
      </div>

      <!-- FOOD -->
      <div
        class="flex-1 max-w-[140px] flex items-center justify-center gap-2 text-gray-500"
      >
        <img src="/assets/icons/header/food.svg" alt="Food" />
        <span class="text-sm font-medium">{{
          "landing-page-builder.food" | translate
        }}</span>
      </div>

      <!-- SHOPPING -->
      <div
        class="flex-1 max-w-[140px] flex items-center justify-center gap-2 text-gray-500"
      >
        <img src="/assets/icons/header/shopping.svg" alt="Shopping" />
        <span class="text-sm font-medium">{{
          "landing-page-builder.shopping" | translate
        }}</span>
      </div>
    </div>

    <div class="flex items-center gap-2">
      <div class="w-10 h-10 flex items-center justify-center">
        <img src="/assets/icons/header/search.svg" alt="" />
      </div>

      <div class="w-10 h-10 flex items-center justify-center">
        <img src="/assets/icons/header/menu.svg" alt="" />
      </div>

      <div class="w-10 h-10 flex items-center justify-center">
        <img
          class="w-8 h-8 rounded-full ring-offset-2 ring-2 ring-orange-500"
          src="/assets/imgs/avatar.jpg"
          alt="Avatar"
        />
      </div>
    </div>
  </div>
</div>
