import { Component, Input } from '@angular/core';
import {
  CURVE_OPTIONS,
  IMAGE_EFFECTS,
} from 'src/app/core/constants/marketing-builder';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import {
  ElementSelected,
  Layer,
} from 'src/app/core/models/interfaces/marketing-builder';

@Component({
  selector: 'app-effect-image',
  templateUrl: './effect-image.component.html',
  styleUrls: ['./effect-image.component.scss'],
})
export class EffectImageComponent {
  @Input() elementSelected: ElementSelected;
  @Input() layers: Layer[] = [];

  readonly SoctripIcons = SoctripIcons;
  readonly ImageEffects = IMAGE_EFFECTS;
  readonly effectOptions = CURVE_OPTIONS;
  readonly builderPrefix = 'landing-page-builder.';

  isExpand: boolean = true;

  get blockData() {
    return this.layers[this.elementSelected.layerIndex].blocks[
      this.elementSelected.blockIndex
    ];
  }

  onToggleExpand() {
    this.isExpand = !this.isExpand;
  }

  onChangeEffectImage(arg0: any) {
    throw new Error('Method not implemented.');
  }
}
