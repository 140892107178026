<div class="bg-white z-10">
  <div class="mx-auto max-w-[1200px] gap-4 py-[60px] px-3">
    <div class="mx-auto flex flex-col justify-between gap-4 pb-4 lg:flex-row">
      <div class="flex w-full flex-wrap justify-between gap-3">
        <!-- ABOUT -->
        <div class="flex-1">
          <h3 class="mb-1 font-semibold lg:mb-3">
            {{ "footer.about" | translate | sentenceCase }}
          </h3>
          <ul class="flex flex-col gap-2 lg:gap-3">
            <li
              *ngFor="let item of about"
              class="cursor-pointer hover:underline"
              (click)="clickItem(item.link)"
              [attr.data-key]="item.label"
            >
              {{ item.label | translate | sentenceCase }}
            </li>
          </ul>
        </div>
        <!-- BOOKING -->
        <div class="flex-1">
          <h3 class="mb-1 font-semibold lg:mb-3">
            {{ "footer.booking" | translate | sentenceCase }}
          </h3>
          <ul class="flex flex-col gap-2 lg:gap-3">
            <li
              *ngFor="let item of booking"
              class="cursor-pointer hover:underline"
              (click)="clickItem(item.link)"
              [attr.data-key]="item.label"
            >
              {{ item.label | translate | sentenceCase }}
            </li>
          </ul>
        </div>
        <!-- OTHER -->
        <div class="flex-1">
          <h3 class="mb-1 font-semibold lg:mb-3">
            {{ "footer.order-service" | translate | sentenceCase }}
          </h3>
          <ul class="flex flex-col gap-2 lg:gap-3">
            <li
              *ngFor="let item of otherServices"
              class="cursor-pointer hover:underline"
              (click)="clickItem(item.link)"
              [attr.data-key]="item.label"
            >
              {{ item.label | translate | sentenceCase }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- CONTACT -->
    <div
      class="flex flex-wrap items-start justify-between gap-2 border-t-[3px] border-dashed pt-4 pb-3 lg:gap-3"
    >
      <div
        class="flex flex-1 flex-col gap-2"
        *ngFor="let item of contact"
        [attr.data-key]="item.origin"
      >
        <h3 class="font-semibold">{{ item.origin }}</h3>
        <div class="flex flex-col gap-2">
          <div class="flex items-center gap-2">
            <span class="min-w-[20px]">
              <img src="/assets/icons/footer_phone.svg" alt="Footer Phone" />
            </span>

            <span
              (click)="call(item.phone)"
              class="cursor-pointer text-[14px] hover:underline"
            >
              {{ item.phone }}
            </span>
          </div>

          <div class="flex items-center gap-2">
            <span class="min-w-[20px]">
              <img src="/assets/icons/footer_email.svg" alt="Footer Phone" />
            </span>

            <span
              (click)="email(item.mail)"
              class="cursor-pointer text-[14px] hover:underline"
            >
              {{ item.mail }}
            </span>
          </div>
          <div class="flex items-start gap-2">
            <span class="min-w-[20px]">
              <img
                class="h-[20px] w-[20px]"
                src="/assets/icons/footer_location.svg"
                alt="Footer Phone"
              />
            </span>
            <span class="text-[14px]">{{ item.address }}</span>
          </div>
        </div>
      </div>
      <div class="hidden flex-1 gap-3 lg:flex min-w-[200px] justify-center">
        <span class="text-nowrap text-[14px]">
          {{
            "footer.all-right" | translate: { name: allRightBy } | sentenceCase
          }}
        </span>
      </div>
    </div>
    <!-- SUBSCRIPTION -->
    <div class="flex-1 border-t-[3px] border-dashed pt-2 md:hidden">
      <div
        *ngIf="region === 'VN'"
        class="flex w-full items-center justify-center gap-2"
      >
        <img
          src="/assets/icons/footer_notification.png"
          alt="Footer Notification"
        />
        <img src="/assets/icons/footer_registry.png" alt="Footer Registry" />
      </div>
    </div>
    <div class="flex-1 w-full justify-center gap-3 pt-4 lg:hidden">
      <span class="text-nowrap text-[14px]">
        {{
          "footer.all-right" | translate: { name: allRightBy } | sentenceCase
        }}
      </span>
    </div>
  </div>
</div>
