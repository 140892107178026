<div>
  <!-- DISPLAY -->
  <div class="px-5 pb-5 border-b">
    <h2 class="py-4 font-semibold text-sm">
      {{ builderPrefix + "display" | translate }}
    </h2>

    <div class="flex flex-col gap-3">
      <!-- Line -->
      <div class="flex items-center justify-between">
        <span class="text-gray-500 text-sm h-9 leading-9 pr-3">{{
          builderPrefix + "line" | translate
        }}</span>
        <p-inputNumber
          class="h-9"
          [min]="1"
          [max]="5"
          [(ngModel)]="blockData.line"
          (onInput)="onLineChange($event)"
        />
      </div>

      <!-- Items per line -->
      <div class="flex items-center justify-between">
        <span class="text-gray-500 text-sm h-9 leading-9 pr-3"
          >Items per line
        </span>
        <p-inputNumber
          class="h-9"
          [min]="1"
          [max]="5"
          [(ngModel)]="blockData.itemPerLine"
          (onInput)="onItemPerLineChange($event)"
        />
      </div>
    </div>
  </div>

  <!-- FILTER PRODUCTS -->
  <div class="px-5 pb-5 border-b">
    <h2 class="py-4 font-semibold text-sm">
      {{ builderPrefix + "filter-products" | translate }}
    </h2>

    <div class="flex flex-col gap-4">
      <!-- Module -->
      <p-dropdown styleClass="h-9 w-full" [options]="['Shop']"></p-dropdown>

      <!-- Filter -->
      <div class="rounded-lg h-9 flex items-center p-[2px] bg-gray-100">
        <div
          *ngFor="let tab of filterTab"
          (click)="onFilterTypeChange(tab.value)"
          [ngClass]="
            (blockData.isSpecificProduct &&
              tab.value === filterTabEnum.SPECIFIC_PRODUCTS) ||
            (!blockData.isSpecificProduct &&
              tab.value === filterTabEnum.FILTERS)
              ? 'bg-white font-semibold'
              : 'font-medium text-gray-500'
          "
          class="flex-1 h-8 flex items-center justify-center rounded-md text-sm cursor-pointer"
        >
          {{ builderPrefix + tab.name | translate | sentenceCase}}
        </div>
      </div>

      <!-- Estimated product found -->
      <div class="px-4 py-2 flex flex-col gap-1 rounded-lg bg-primary-50">
        <span class="text-gray-500 text-sm">
          {{ builderPrefix + "estimated-product-found" | translate }}</span
        >
        <span class="text-xl font-semibold text-primary-600">{{
          blockData.totalProducts || 0
        }}</span>
      </div>

      <!-- FILTERS -->
      <ng-container *ngIf="!blockData.isSpecificProduct">
        <ng-container *ngFor="let filter of blockData.filters">
          <ng-container [ngSwitch]="filter">
            <!-- Promotion program -->
            <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.PROMOTION">
              <div
                *ngIf="blockData.promotions"
                class="p-4 border bg-gray-50 rounded-lg flex flex-col gap-3"
              >
                <span class="text-xs font-medium">{{
                  builderPrefix + "product-list-filters-by.promotion-programs"
                    | translate
                    | uppercase
                }}</span>
                <div>
                  <div
                    class="button"
                    [ngClass]="isModalPromotion ? 'bg-gray-600/10' : 'bg-white'"
                    (click)="onToggleProgram()"
                  >
                    {{
                      builderPrefix + "product-list-filters-by.select-program"
                        | translate
                    }}
                  </div>
                  <p-overlay [(visible)]="isModalPromotion" appendTo="body">
                    <ul
                      class="bg-white rounded-lg border w-[472px] max-h-[360px] p-1 mt-1 overflow-y-auto"
                    >
                      <div
                        *ngIf="!promotionPrograms?.length"
                        class="py-4 text-center text-sm font-medium"
                      >
                        {{
                          builderPrefix +
                            "product-list-filters-by.no-data-of-campaigns"
                            | translate
                        }}
                      </div>
                      <li
                        *ngFor="let campaign of promotionPrograms"
                        class="h-[60px] flex items-center gap-3 px-3"
                      >
                        <p-checkbox
                          [value]="{
                            id: campaign.id,
                            title: campaign.title,
                            thumbnail: campaign.filter_thumbnail
                          }"
                          [(ngModel)]="blockData.promotions"
                        ></p-checkbox>
                        <app-custom-image
                          styleClass="w-10 h-10"
                          imageClass="rounded"
                          [imgId]="campaign.filter_thumbnail || ''"
                          [alt]="campaign.title || ''"
                        ></app-custom-image>

                        <div class="flex flex-col gap-1">
                          <div class="flex items-center gap-1">
                            <span class="text-sm font-medium">{{
                              campaign.title
                            }}</span>
                            <span
                              class="text-xs px-2 py-[2px] rounded-full font-medium text-primary-600 bg-primary-50"
                              >{{
                                "section-status.on-going"
                                  | translate
                                  | sentenceCase
                              }}</span
                            >
                          </div>
                          <div>
                            <span class="text-sm text-gray-500"
                              >{{ campaign.start_at | date: "MM/dd/yyyy" }} -
                              {{
                                (campaign.end_at | date: "MM/dd/yyyy") ||
                                  (builderPrefix + "no-expired-date"
                                    | translate)
                              }}
                              • 1,394 {{ "common.products" | translate }}</span
                            >
                          </div>
                        </div>
                      </li>
                    </ul>
                  </p-overlay>
                </div>

                <div
                  *ngIf="blockData.promotions.length"
                  class="flex flex-wrap gap-2"
                >
                  <div
                    *ngFor="
                      let promotion of blockData.promotions;
                      let i = index
                    "
                    class="h-7 rounded-full flex items-center gap-1 bg-gray-200 p-1 w-fit"
                  >
                    <div class="w-5">
                      <app-custom-image
                        [imgId]="promotion.thumbnail"
                        [alt]="promotion.title"
                        imageClass="w-5 !h-5 rounded-full"
                      ></app-custom-image>
                    </div>
                    <span class="text-sm font-medium">{{
                      promotion.title
                    }}</span>
                    <i
                      class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
                      (click)="
                        onDeleteItem(
                          PRODUCT_LIST_FILTER.PROMOTION,
                          promotion.id,
                          i
                        )
                      "
                    ></i>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- Keywords -->
            <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.KEYWORD">
              <div
                *ngIf="blockData.keywords"
                class="p-4 border bg-gray-50 rounded-lg flex flex-col gap-3"
              >
                <span class="text-xs font-medium">
                  {{
                    builderPrefix + "product-list-filters-by.keywords"
                      | translate
                      | uppercase
                  }}
                </span>

                <input
                  [(ngModel)]="keyword"
                  class="h-9"
                  type="text"
                  pInputText
                  placeholder="{{
                    builderPrefix + 'enter-keyword' | translate
                  }}"
                  (keyup.enter)="onAddKeyword($event)"
                />

                <div
                  *ngIf="blockData.keywords.length"
                  class="flex flex-wrap gap-2"
                >
                  <div
                    *ngFor="let keyword of blockData.keywords; let i = index"
                    class="h-7 rounded-full flex items-center gap-1 bg-gray-200 pl-3 pr-2 w-fit max-w-full"
                  >
                    <span class="text-sm font-medium">{{ keyword }}</span>
                    <i
                      class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
                      (click)="onDeleteItem(PRODUCT_LIST_FILTER.KEYWORD, '', i)"
                    ></i>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- Categories -->
            <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.CATEGORY">
              <div
                *ngIf="blockData.categories"
                class="p-4 border bg-gray-50 rounded-lg flex flex-col gap-3"
              >
                <span class="text-xs font-medium"
                  >{{
                    builderPrefix + "product-list-filters-by.categories"
                      | translate
                      | uppercase
                  }}
                </span>
                <div>
                  <div
                    class="button"
                    [ngClass]="isModalCategory ? 'bg-gray-600/10' : 'bg-white'"
                    (click)="isModalCategory = !isModalCategory"
                  >
                    {{ builderPrefix + "select-category" | translate }}
                  </div>
                  <p-overlay appendTo="body" [(visible)]="isModalCategory">
                    <div
                      class="mb-1 flex w-[1000px] h-[25rem] overflow-x-auto overflow-y-clip border bg-white border-gray-200 shadow-lg rounded-lg"
                    >
                      <div
                        *ngFor="let categories of categoriesGlobalList"
                        class="overflow-auto border-r border-gray-200 w-1/5"
                      >
                        <div
                          *ngFor="let category of categories"
                          (mouseover)="hoverCategory($event, category)"
                          (click)="selectCategory($event, category)"
                          [ngClass]="{
                            'bg-primary-50 text-primary-700': isCategoryAdded(
                              category.id
                            )
                          }"
                          class="flex justify-between items-center p-2 gap-2 hover:bg-primary-50 hover:text-primary-700 hover:cursor-pointer"
                        >
                          <span class="text-sm line-clamp-1">{{
                            category.name
                          }}</span>
                          <i
                            *ngIf="category.sub_catalogs"
                            [class]="SoctripIcons.CHEVRON_RIGHT"
                          ></i>
                        </div>
                      </div>
                    </div>
                  </p-overlay>
                </div>

                <div
                  *ngIf="blockData.categories.length"
                  class="flex flex-wrap gap-2"
                >
                  <div
                    *ngFor="let category of blockData.categories; let i = index"
                    class="h-7 rounded-full flex items-center gap-1 bg-gray-200 pl-3 pr-2 w-fit max-w-full"
                  >
                    <span class="text-sm font-medium">{{ category.name }}</span>
                    <i
                      class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
                      (click)="
                        onDeleteItem(
                          PRODUCT_LIST_FILTER.CATEGORY,
                          category.id,
                          i
                        )
                      "
                    ></i>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- Product rating -->
            <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.PRODUCT_RATING">
              <div class="p-4 border bg-gray-50 rounded-lg flex flex-col gap-3">
                <span class="text-xs font-medium">
                  {{
                    builderPrefix + "product-list-filters-by.product-rating"
                      | translate
                      | uppercase
                  }}
                </span>

                <div>
                  <div
                    class="button"
                    [ngClass]="
                      isModalProductRating ? 'bg-gray-600/10' : 'bg-white'
                    "
                    (click)="isModalProductRating = !isModalProductRating"
                  >
                    {{ builderPrefix + "select-rating" | translate }}
                  </div>
                  <p-overlay appendTo="body" [(visible)]="isModalProductRating">
                    <div
                      class="mt-1 p-1 bg-white w-[284px] rounded-lg max-h-[400px] overflow-y-auto border shadow-md"
                    >
                      <div
                        class="p-3 flex items-center gap-3"
                        *ngFor="let STAR of STARS"
                      >
                        <p-radioButton
                          [value]="STAR"
                          [(ngModel)]="blockData.product_ratings"
                        ></p-radioButton>

                        <p-rating
                          [ngModel]="STAR"
                          [cancel]="false"
                          [readonly]="true"
                        />
                      </div>
                    </div>
                  </p-overlay>
                </div>

                <div
                  *ngIf="blockData.product_ratings"
                  class="flex flex-wrap gap-2"
                >
                  <div
                    class="h-7 rounded-full flex items-center gap-1 bg-gray-200 pl-3 pr-2 w-fit max-w-full"
                  >
                    <div class="flex items-center gap-1">
                      <p-rating
                        class="flex"
                        [stars]="1"
                        [ngModel]="1"
                        [cancel]="false"
                        [readonly]="true"
                      />
                      <span class="text-sm font-medium">
                        {{ blockData.product_ratings }}</span
                      >
                    </div>
                    <i
                      class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
                      (click)="
                        onDeleteItem(
                          PRODUCT_LIST_FILTER.PRODUCT_RATING,
                          '',
                          blockData.product_ratings
                        )
                      "
                    ></i>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- City -->
            <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.CITY">
              <div
                *ngIf="blockData.cities"
                class="p-4 border bg-gray-50 rounded-lg flex flex-col gap-3"
              >
                <span class="text-xs font-medium">
                  {{
                    builderPrefix +
                      "product-list-filters-by.cities-(shop-location)"
                      | translate
                      | uppercase
                  }}
                </span>

                <div>
                  <div
                    class="button"
                    [ngClass]="isModalCity ? 'bg-gray-600/10' : 'bg-white'"
                    (click)="isModalCity = !isModalCity"
                  >
                    {{ builderPrefix + "select-city" | translate }}
                  </div>

                  <p-overlay appendTo="body" [(visible)]="isModalCity">
                    <div
                      class="mt-1 p-1 bg-white w-[284px] rounded-lg border shadow-md"
                    >
                      <div class="p-1">
                        <input
                          class="h-9 w-full"
                          placeholder="{{
                            'common.city' | translate | sentenceCase
                          }}"
                          type="text"
                          pInputText
                        />
                      </div>
                      <div class="max-h-[400px] overflow-y-auto">
                        <div
                          class="p-3 flex items-center gap-3"
                          *ngFor="let city of cities"
                        >
                          <p-checkbox
                            [value]="{
                              id: city.province_id,
                              name: city.province_name
                            }"
                            [(ngModel)]="blockData.cities"
                          ></p-checkbox>

                          <span class="text-sm font-medium">{{
                            city.province_name
                          }}</span>
                        </div>
                      </div>
                    </div>
                  </p-overlay>
                </div>

                <div
                  *ngIf="blockData.cities.length"
                  class="flex flex-wrap gap-2"
                >
                  <div
                    *ngFor="let city of blockData.cities; let i = index"
                    class="h-7 rounded-full flex items-center gap-1 bg-gray-200 pl-3 pr-2 w-fit max-w-full"
                  >
                    <span class="text-sm font-medium"> {{ city.name }}</span>
                    <i
                      class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
                      (click)="
                        onDeleteItem(PRODUCT_LIST_FILTER.CITY, city.id, i)
                      "
                    ></i>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- Shop -->
            <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.SHOP">
              <div
                *ngIf="blockData.shops"
                class="p-4 border bg-gray-50 rounded-lg flex flex-col gap-3"
              >
                <span class="text-xs font-medium">
                  {{
                    builderPrefix + "product-list-filters-by.shop"
                      | translate
                      | uppercase
                  }}
                </span>

                <div>
                  <div
                    class="button"
                    [ngClass]="isModalShop ? 'bg-gray-600/10' : 'bg-white'"
                    (click)="isModalShop = !isModalShop"
                  >
                    {{ builderPrefix + "select-shop" | translate }}
                  </div>

                  <app-add-shops-modal
                    [(isModal)]="isModalShop"
                    [(selectedShops)]="blockData.shops"
                    [cities]="cities"
                  >
                  </app-add-shops-modal>
                </div>

                <div
                  *ngIf="blockData.shops.length"
                  class="flex flex-wrap gap-2"
                >
                  <div
                    *ngFor="let shop of blockData.shops; let i = index"
                    class="h-7 rounded-full flex items-center gap-1 bg-gray-200 p-1 pr-2 w-fit max-w-full"
                  >
                    <div class="w-5 min-w-[20px] h-5">
                      <app-custom-image
                        styleClass="w-full h-full rounded-full overflow-hidden"
                        [imgId]="shop.avatar"
                        [alt]="shop.name"
                      ></app-custom-image>
                    </div>
                    <span class="text-sm font-medium truncate">
                      {{ shop.name }}</span
                    >
                    <i
                      class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
                      (click)="
                        onDeleteItem(PRODUCT_LIST_FILTER.SHOP, shop.id, i)
                      "
                    ></i>
                  </div>
                </div>
              </div>
            </ng-container>

            <!-- Price range -->
            <ng-container *ngSwitchCase="PRODUCT_LIST_FILTER.PRICE_RANGE">
              <div
                *ngIf="
                  blockData.minPrice !== undefined ||
                  blockData.maxPrice !== undefined
                "
                class="p-4 border bg-gray-50 rounded-lg flex flex-col gap-3"
              >
                <div class="flex items-center">
                  <span class="flex-1 text-xs font-medium">
                    {{
                      builderPrefix + "product-list-filters-by.price-range"
                        | translate
                        | uppercase
                    }}
                  </span>
                  <i
                    class="flex-2 text-gray-500 cursor-pointer {{
                      SoctripIcons.X_CLOSE
                    }}"
                  ></i>
                </div>

                <div class="flex items-center gap-2">
                  <p-inputNumber
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    inputStyleClass="w-[132px] h-9"
                    [(ngModel)]="blockData.minPrice"
                  />
                  <span>-</span>
                  <p-inputNumber
                    mode="currency"
                    currency="USD"
                    locale="en-US"
                    inputStyleClass="w-[132px] h-9"
                    [(ngModel)]="blockData.maxPrice"
                  />
                </div>
              </div>
            </ng-container>
          </ng-container>
        </ng-container>
      </ng-container>

      <!-- SPECIFIC PRODUCTS -->
      <div
        *ngIf="blockData.isSpecificProduct"
        class="p-4 border bg-gray-50 rounded-lg flex flex-col gap-3"
      >
        <div class="flex items-center">
          <span class="flex-1 text-xs font-medium">
            {{
              builderPrefix + "product-list-filters-by.specific-product"
                | translate
                | uppercase
            }}
          </span>
          <i
            class="flex-2 text-gray-500 cursor-pointer {{
              SoctripIcons.X_CLOSE
            }}"
          ></i>
        </div>
        <div>
          <div
            class="button"
            [ngClass]="isModalProduct ? 'bg-gray-600/10' : 'bg-white'"
            (click)="isModalProduct = !isModalProduct"
          >
            {{ builderPrefix + "select-product" | translate }}
          </div>

          <app-add-product-modal
            [(selectedProducts)]="blockData.specific_products"
            [(isModal)]="isModalProduct"
          >
          </app-add-product-modal>
        </div>

        <div
          *ngIf="blockData.specific_products.length"
          class="flex flex-wrap gap-2"
        >
          <div
            *ngFor="let product of blockData.specific_products; let i = index"
            class="h-7 rounded-full flex items-center gap-1 bg-gray-200 p-1 pr-2 w-fit max-w-full"
          >
            <div class="w-5 min-w-[20px] h-5">
              <app-custom-image
                styleClass="w-full h-full rounded-full overflow-hidden"
                [imgId]="product.avatar"
                [alt]="product.name"
              ></app-custom-image>
            </div>
            <span class="text-sm font-medium truncate">
              {{ product.name }}</span
            >
            <i
              class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
              (click)="onDeleteProduct(product.id)"
            ></i>
          </div>
        </div>
      </div>

      <!-- Count and add filter -->
      <div class="flex items-center">
        <!-- <div class="flex-1 button" (click)="onCountProduct()">
          Count product
        </div> -->
        <div class="flex-1">
          <app-button
            [loading]="isCounting"
            styleClass="w-full"
            variant="outline-secondary"
            [label]="builderPrefix + 'count-product' | translate"
            (onClick)="onCountProduct()"
          ></app-button>
        </div>
        <div
          *ngIf="!blockData.isSpecificProduct"
          (click)="onToggleFilter()"
          class="flex-1 button ml-2"
          [ngClass]="isModalFilter ? 'bg-gray-600/10' : 'bg-white'"
        >
          {{ builderPrefix + "add-filter" | translate }}
        </div>

        <p-overlay [(visible)]="isModalFilter" appendTo="body">
          <ul
            class="mt-1 p-1 bg-white w-[284px] rounded-lg max-h-[400px] overflow-y-auto border shadow-md"
          >
            <li
              *ngFor="let filter of filtersBy"
              [ngClass]="
                isDisableFilter(filter.value)
                  ? 'opacity-50'
                  : 'hover:bg-gray-100 cursor-pointer'
              "
              class="p-3 text-sm font-medium transition rounded"
              (click)="onAddFilter(filter.value)"
            >
              {{
                filter.name
                  ? (builderPrefix +
                      "product-list-filters-by." +
                      filter.name.replaceAll(" ", "-")
                    | lowercase
                    | translate)
                  : ""
              }}
            </li>
          </ul>
        </p-overlay>
      </div>
    </div>
  </div>

  <!-- SORT PRODUCTS -->
  <div class="px-5 pb-5">
    <h2 class="py-4 font-semibold text-sm">
      {{ builderPrefix + "sort-products.sort-products" | translate }}
    </h2>

    <div
      class="flex flex-col gap-3"
      [ngClass]="{
        'pb-4': blockData.sorts.length > 0
      }"
    >
      <div
        *ngFor="let sort of blockData.sorts; let i = index"
        class="flex items-center gap-2"
      >
        <div class="flex flex-col">
          <i
            class="{{ SoctripIcons.CHEVRON_UP }}"
            [ngClass]="i === 0 ? 'opacity-50' : 'cursor-pointer'"
            (click)="i !== 0 ? onMoveUpSort(i) : null"
          ></i>
          <i
            class="{{ SoctripIcons.CHEVRON_DOWN }}"
            [ngClass]="
              i === blockData.sorts.length - 1 ? 'opacity-50' : 'cursor-pointer'
            "
            (click)="
              i !== blockData.sorts.length - 1 ? onMoveDownSort(i) : null
            "
          ></i>
        </div>

        <div class="flex-1 max-w-[calc(100%-48px)] flex items-center gap-2">
          <div class="w-1/2">
            <p-dropdown
              appendTo="body"
              [options]="SORT_PRODUCTS"
              [(ngModel)]="sort.key"
              optionValue="value"
              optionLabel="name"
              styleClass="w-full h-9"
              optionDisabled="disabled"
            >
              <ng-template let-item pTemplate="item">
                <span>
                  {{
                    builderPrefix + "sort-products." + item.name
                      | lowercase
                      | translate
                  }}
                </span>
              </ng-template>
              <ng-template let-selectedItem pTemplate="selectedItem">
                <span>
                  {{
                    builderPrefix + "sort-products." + selectedItem.name
                      | lowercase
                      | translate
                  }}
                </span>
              </ng-template>
            </p-dropdown>
          </div>

          <div class="w-1/2">
            <p-dropdown
              appendTo="body"
              [options]="getSortValueByKey(sort.key)"
              [(ngModel)]="sort.value"
              styleClass="w-full h-9"
              optionValue="value"
              optionLabel="name"
            >
              <ng-template let-item pTemplate="item">
                <span>
                  {{
                    builderPrefix +
                      "sort-products." +
                      item.name.replaceAll(" ", "-")
                      | lowercase
                      | translate
                  }}
                </span>
              </ng-template>
              <ng-template let-selectedItem pTemplate="selectedItem">
                <span>
                  {{
                    builderPrefix +
                      "sort-products." +
                      selectedItem.name.replaceAll(" ", "-")
                      | lowercase
                      | translate
                  }}
                </span>
              </ng-template>
            </p-dropdown>
          </div>
        </div>
        <i
          class="{{ SoctripIcons.X_CLOSE }} cursor-pointer"
          (click)="onDeleteSort(sort.key)"
        ></i>
      </div>
    </div>

    <div>
      <div
        class="button"
        [ngClass]="isModalSort ? 'bg-gray-600/10' : 'bg-white'"
        (click)="onToggleAddSort()"
      >
        {{ builderPrefix + "add-sort" | translate }}
      </div>
      <p-overlay [(visible)]="isModalSort" appendTo="body">
        <ul
          class="mb-1 p-1 bg-white w-[284px] rounded-lg max-h-[400px] overflow-y-auto border shadow-md"
        >
          <li
            *ngFor="let SORT of SORT_PRODUCTS"
            class="p-3 text-sm font-medium transition rounded"
            [ngClass]="
              isDisableSort(SORT.value)
                ? 'opacity-50'
                : 'hover:bg-gray-100 cursor-pointer'
            "
            (click)="onAddSort(SORT.value, SORT.scope)"
          >
            {{
              builderPrefix + "sort-products." + SORT.name
                | lowercase
                | translate
            }}
          </li>
        </ul>
      </p-overlay>
    </div>
  </div>
</div>
