import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import {
  BLOCK_TYPE,
  PREVIEW_DEVICE,
} from 'src/app/core/enum/marketing-builder';
import { Layer } from 'src/app/core/models/interfaces/marketing-builder';
import { FileService } from 'src/app/core/services/file.service';

@Component({
  selector: 'app-landing-page-preview',
  templateUrl: './landing-page-preview.component.html',
  styleUrls: ['./landing-page-preview.component.scss'],
})
export class LandingPagePreviewComponent {
  @Input() data: Layer[] = [];
  @Input() previewDevice: string = PREVIEW_DEVICE.DESKTOP;

  readonly BLOCK_TYPE = BLOCK_TYPE;
  readonly SoctripIcons = SoctripIcons;

  constructor(
    public fileService: FileService,
    private sanitizer: DomSanitizer,
  ) {}

  get isDesktop() {
    return this.previewDevice === PREVIEW_DEVICE.DESKTOP;
  }

  getGridTemplate(row: number) {
    return `repeat(${row}, minmax(0, 1fr))`;
  }

  generateArray(length: number) {
    return Array(length).fill(1);
  }

  convertToIframeLink(youtubeUrl?: string) {
    if (!youtubeUrl) return '';

    const urlPattern = /https?:\/\/(www\.)?youtube\.com\/watch\?v=([^&]+)/;
    const match = youtubeUrl.match(urlPattern);

    if (match && match[2]) {
      const videoId = match[2];
      return this.sanitizer.bypassSecurityTrustResourceUrl(
        `https://www.youtube.com/embed/${videoId}`,
      );
    } else {
      return '';
    }
  }
}
