<p-dialog
  appendTo="body"
  [modal]="true"
  [draggable]="false"
  [header]="'landing-page-builder.select-shop' | translate"
  [(visible)]="isModal"
  (onHide)="setIsModal(false)"
  [breakpoints]="{ '1199px': '75vw', '575px': '90vw' }"
  [style]="{ 'min-width': '860px' }"
  contentStyleClass="!pb-1"
>
  <div
    *ngIf="isFetched && isLoading"
    class="absolute z-10 flex top-4 right-0 bottom-0 left-0 items-center justify-center bg-gray-200/50"
  >
    <p-progressSpinner styleClass="w-[1rem] h-[1rem]"></p-progressSpinner>
  </div>

  <div class="flex items-center justify-between">
    <span class="p-input-icon-left w-2/5 mb-3 mt-1">
      <i class="pi pi-search"></i>
      <input
        pInputText
        type="text"
        [(ngModel)]="keyword"
        (ngModelChange)="this.keywordChanged.next($event)"
        [placeholder]="'common.search-by-shop-name' | translate"
        class="w-full h-9 rounded-lg"
      />
    </span>

    <div class="flex items-center gap-2">
      <p-dropdown
        appendTo="body"
        [(ngModel)]="rating"
        styleClass="h-9 items-center"
        [placeholder]="'common.shop-rating' | translate"
        [options]="RATINGS"
        (onChange)="onFilterShops()"
      >
        <ng-template pTemplate="selectedItem">
          <p-rating
            *ngIf="rating"
            [ngModel]="rating"
            [cancel]="false"
            [readonly]="true"
          ></p-rating>
        </ng-template>

        <ng-template pTemplate="item" let-star>
          <p-rating
            [ngModel]="star"
            [cancel]="false"
            [readonly]="true"
          ></p-rating>
        </ng-template>
      </p-dropdown>
      <p-dropdown
        appendTo="body"
        [(ngModel)]="location"
        [options]="cities"
        optionValue="province_id"
        optionLabel="province_name"
        styleClass="h-9 items-center"
        [placeholder]="'common.location' | translate | sentenceCase"
        (onChange)="onFilterShops()"
      ></p-dropdown>
    </div>
  </div>

  <div class="border rounded-lg overflow-hidden">
    <p-table
      #dt
      [value]="shops"
      dataKey="id"
      [loading]="!isFetched"
      [(selection)]="selectedShops"
      class="relative"
      [scrollable]="true"
      scrollHeight="calc(100vh - 410px)"
      [selectionPageOnly]="true"
    >
      <ng-template pTemplate="loadingbody">
        <tr *ngFor="let row of ROWS_QUANTITY_LOADING">
          <td *ngFor="let col of COLS_QUANTITY_LOADING">
            <p-skeleton></p-skeleton>
          </td>
        </tr>
      </ng-template>

      <ng-template pTemplate="header" class="sticky top-0">
        <tr>
          <th>
            <p-tableHeaderCheckbox />
          </th>
          <th>
            {{ "common.shop-name" | translate | sentenceCase }}
          </th>
          <th>
            {{ "common.rating" | translate | sentenceCase }}
          </th>
          <th>
            {{ "common.total-products" | translate | sentenceCase }}
          </th>
          <th>
            {{ "common.location" | translate | sentenceCase }}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-shop>
        <tr class="p-selectable-row">
          <td>
            <p-tableCheckbox
              [value]="{
                id: shop.id,
                name: shop.name,
                avatar: shop.avatar?.id
              }"
            ></p-tableCheckbox>
          </td>
          <td>
            <div class="flex items-center gap-2">
              <app-custom-image
                [imgId]="shop?.avatar?.id"
                alt="avatar"
                class="min-w-[40px] w-10 h-10 rounded-full overflow-hidden"
              >
              </app-custom-image>

              <div class="flex flex-col gap-1">
                <span class="text-sm font-medium text-gray-600">{{
                  shop.name
                }}</span>
              </div>
            </div>
          </td>
          <td>
            <div class="flex items-center gap-[6px]">
              <img src="/assets/icons/stars.svg" alt="Start" />
              <span>{{ shop?.rating?.toFixed(1) ?? 0 }}</span>
            </div>
          </td>
          <td>
            <span class="ml-1 vertical-align-middle">{{
              shop?.total_products
            }}</span>
          </td>

          <td>
            {{ shop?.street }}, {{ shop?.ward?.name }},
            {{ shop?.district?.name }}, {{ shop?.province?.name }}
            {{ shop?.province?.postal_code }}
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="summary">
        <div
          *ngIf="shops.length === 0 && isFetched"
          class="bg-white w-full flex flex-col gap-2 items-center py-4"
        >
          <img
            class="inline-block min-w-[5rem] w-20 h-20 object-cover object-center"
            src="assets/imgs/empty-data.svg"
            [alt]="'common.no-data' | translate | sentenceCase"
          />
          <span class="text-gray-400 text-sm">
            {{ "common.no-data" | translate | sentenceCase }}
          </span>
        </div>
      </ng-template>
    </p-table>
  </div>

  <ng-template pTemplate="footer">
    <app-paginator
      [stylesClass]="'!p-0'"
      [totalRecords]="_totalElement"
      [dataLength]="shops.length"
      [(pagination)]="pagination"
      (onChange)="_initShops()"
    ></app-paginator>
    <div
      [ngClass]="{ 'pt-4': _totalElement <= 5 }"
      class="w-full flex justify-end gap-1"
    >
      <app-button
        [label]="'section-action.cancel' | translate | sentenceCase"
        size="lg"
        variant="outline-secondary"
        (onClick)="onCancelAddShop()"
      ></app-button>

      <app-button
        size="lg"
        [label]="'section-action.add' | translate | sentenceCase"
        (onClick)="onAddShops()"
      ></app-button>
    </div>
  </ng-template>
</p-dialog>
