import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { SoctripIcons } from 'src/app/core/constants/soctrip-icon.enum';
import { FileService } from 'src/app/core/services/file.service';
import { environment } from 'src/environments/environment';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-add-voucher-modal',
  templateUrl: './add-voucher-modal.component.html',
  styleUrls: ['./add-voucher-modal.component.scss'],
})
export class AddVoucherModalComponent implements OnInit {
  protected readonly SoctripIcons = SoctripIcons;

  @Input() max: number = 0;
  @Input({ required: true }) isModal = false;
  @Input({ required: true }) selectedVouchers: any[] = [];

  @Output() isModalChange = new EventEmitter<boolean>();
  @Output() selectedVouchersChange = new EventEmitter<any[]>();

  _vouchers: any[] = [];
  _totalVouchers = 0;

  isFetching = false;
  isFetched = false;

  pagination = {
    rows: 10,
    page: 0,
  };
  keyword = '';
  keywordChange$ = new Subject<string>();
  language: string = localStorage.getItem('lang') || 'en';
  filter = '';
  module_filter = 'modules@=VOUCHER-SHOP|SOCTRIP-VOUCHER|SHOP';

  constructor(
    private httpClient: HttpClient,
    public fileService: FileService,
    private cd: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.filter = this.module_filter;
    this.onSearchVoucher();
  }

  _fetchVouchers(resetPage: boolean) {
    if (resetPage) {
      this.onResetPagination();
    }
    if (this.isFetched) {
      this.isFetching = true;
    }

    this.httpClient
      .get<any>(
        `${
          environment.BE_URL
        }promotion/vouchers/marketing-campaign?tab=ALL&language=${
          this.language
        }&pageNum=${this.pagination.page}&pageSize=${
          this.pagination.rows
        }&filters=${encodeURIComponent(this.filter)}`,
      )
      .subscribe({
        next: (res) => {
          this._vouchers = res.data.data;
          this._totalVouchers = res.data.totalElement;
          if (this.isFetched) {
            this.isFetching = false;
          } else {
            this.isFetched = true;
          }
          this.cd.detectChanges();
        },
        error: () => {
          if (this.isFetched) {
            this.isFetching = false;
          } else {
            this.isFetched = true;
          }
          this.cd.detectChanges();
        },
      });
  }

  onResetPagination() {
    this.pagination = {
      page: 0,
      rows: 10,
    };
  }

  setIsModal(isModal: boolean) {
    this.isModal = isModal;
    this.isModalChange.emit(this.isModal);
    this.filter = this.module_filter;
    this.keyword = '';
  }

  onAddProducts() {
    this.setIsModal(false);
    this.selectedVouchers = [...this.selectedVouchers].slice(0, this.max);
    this.selectedVouchersChange.emit(this.selectedVouchers);
  }

  onCancelAddProduct() {
    this.setIsModal(false);
  }

  isRowDisabled(voucher: any) {
    if (this.selectedVouchers.length >= this.max) {
      return !this.selectedVouchers.some((item) => item.id === voucher.id);
    }
    return false;
  }

  formatDiscount(voucher: any): string {
    const discountRange = voucher?.voucher_discount_range?.[0];
    if (!discountRange) return '';

    const currency = discountRange?.currency || '$';
    const minSpend = discountRange.min_order_price
      ? `, min spend ${currency}${discountRange.min_order_price}`
      : '';
    const capped =
      discountRange.is_limit_discount_price && discountRange.max_discount_price
        ? `, capped at ${currency}${discountRange.max_discount_price}`
        : '';

    if (discountRange.discount_type === 'PERCENTAGE') {
      return `${discountRange.discount_percent}%${minSpend}${capped}`;
    } else if (discountRange.discount_type === 'PRICE') {
      return `${currency}${discountRange.discount_amount}${minSpend}${capped}`;
    }

    return '';
  }

  onSearchVoucher() {
    this.keywordChange$
      .pipe(debounceTime(800), distinctUntilChanged())
      .subscribe({
        next: () => {
          if (this.keyword) {
            this.filter = `voucher_code|name@=${this.keyword},${this.module_filter}`;
          } else {
            this.filter = this.module_filter;
          }
          this._fetchVouchers(true);
        },
      });
  }
}
