export enum BLOCK_TYPE {
  IMAGE = 'IMAGE',
  TEXT = 'TEXT',
  SLIDE = 'SLIDE',
  VIDEO = 'VIDEO',
  TAB = 'TAB',
  PRODUCT_LIST = 'PRODUCT_LIST',
  VOUCHER = 'VOUCHER',
}

export enum PREVIEW_DEVICE {
  DESKTOP = 'DESKTOP',
  MOBILE = 'MOBILE',
}

export enum TAB_MEDIA {
  MEDIA1 = 'MEDIA1',
  MEDIA2 = 'MEDIA2',
}

export enum PRODUCT_LIST_FILTER {
  PROMOTION = 'PROMOTION',
  KEYWORD = 'KEYWORD',
  CATEGORY = 'CATEGORY',
  PRODUCT_RATING = 'PRODUCT_RATING',
  CITY = 'CITY',
  SHOP = 'SHOP',
  PRICE_RANGE = 'PRICE_RANGE',
}

export enum TAB_EFFECT_IMAGE {
  CUSTOM = 'CUSTOM',
  ANIMATE = 'ANIMATE',
}

export enum FILTER_TAB_ENUM {
  FILTERS = 'FILTERS',
  SPECIFIC_PRODUCTS = 'SPECIFIC_PRODUCTS',
}
