<div class="px-5 border-t">
  <div class="py-2 flex items-center justify-between">
    <span class="text-sm font-semibold">{{
      builderPrefix + "effect" | translate
    }}</span>
    <div
      (click)="onToggleExpand()"
      class="w-9 h-9 flex items-center justify-center cursor-pointer hover:bg-gray-100 rounded-lg"
    >
      <i
        [ngClass]="isExpand ? SoctripIcons.MINUS : SoctripIcons.PLUS"
        class="text-xl"
      ></i>
    </div>
  </div>

  <div *ngIf="isExpand && blockData.medias[0]" class="pb-5 flex flex-col gap-4">
    <div
      class="h-9 px-[2px] flex items-center gap-2 rounded-lg bg-gray-100 w-full"
    >
      <span
        class="h-8 w-full flex items-center justify-center rounded-lg cursor-pointer hover:bg-white font-semibold text-sm"
        [ngClass]="'ANIMATE' === effect.value ? 'bg-white' : ''"
        (click)="onChangeEffectImage(effect.value)"
        *ngFor="let effect of ImageEffects"
      >
        {{ builderPrefix + effect.title | lowercase | translate }}
      </span>
    </div>

    <div class="flex justify-between gap-5 items-center">
      <span class="flex-1 w-1/2">{{
        builderPrefix + "curve.curve" | translate
      }}</span>
      <p-dropdown
        styleClass=" w-full h-9"
        class="w-[190px]"
        [options]="effectOptions"
        optionLabel="name"
        optionValue="value"
        [(ngModel)]="blockData.curve"
      >
        <ng-template let-item pTemplate="item">
          <span>
            {{
              builderPrefix + "curve." + item.name.replaceAll(" ", "_")
                | lowercase
                | translate
            }}
          </span>
        </ng-template>
        <ng-template let-selectedItem pTemplate="selectedItem">
          <span>
            {{
              builderPrefix + "curve." + selectedItem.name.replaceAll(" ", "_")
                | lowercase
                | translate
            }}
          </span>
        </ng-template>
      </p-dropdown>
    </div>
    <div class="flex justify-between gap-5 items-center">
      <span class="w-1/2">{{ builderPrefix + "duration" | translate }}</span>
      <p-inputNumber
        styleClass="h-9 "
        [(ngModel)]="blockData.duration"
        [suffix]="'ms'"
        placeholder="ms"
      ></p-inputNumber>
    </div>
  </div>
</div>
