<div class="p-4 h-[calc(100vh-60px)] sticky top-[60px]">
  <div
    class="h-full bg-white border shadow-md py-4 rounded-xl w-[360px] overflow-y-auto"
  >
    <div class="px-4">
      <p-selectButton [options]="options" [(ngModel)]="tab" optionLabel="name">
        <ng-template let-item pTemplate="item">
          <span>{{ builderPrefix + item.name | lowercase | translate }}</span>
        </ng-template>
      </p-selectButton>
    </div>

    <!-- BLOCK | LAYOUT -->
    <div class="px-5 pb-5">
      <h2 class="py-4 font-semibold">
        {{ builderPrefix + tab.name | lowercase | translate }}
      </h2>

      <div [ngSwitch]="tab.value">
        <!-- LAYOUT -->
        <div *ngSwitchCase="'LAYOUT'" class="flex flex-col gap-4">
          <div
            *ngFor="let LAYOUT of LAYOUTS"
            class="border rounded-lg bg-white"
            [dndDraggable]="{
              tab: 'LAYOUT',
              data: LAYOUT
            }"
            dndEffectAllowed="move"
            dragImageClass="bg-red-600"
          >
            <div class="flex items-center gap-3 p-3">
              <div
                *ngFor="let item of LAYOUT.value"
                [style.flex]="item"
                class="border h-6 border-dashed border-primary-600 rounded bg-primary-50"
              ></div>
            </div>
          </div>
        </div>

        <!-- BLOCK -->
        <div *ngSwitchCase="'BLOCK'" class="grid grid-cols-3 gap-4">
          <div
            *ngFor="let BLOCK of BLOCKS"
            appearance="raised"
            class="border rounded-lg py-4 flex flex-col items-center justify-center gap-3"
            [dndDraggable]="{
              tab: 'BLOCK',
              data: BLOCK
            }"
            dndDraggingClass="text-primary-600"
            dndEffectAllowed="move"
          >
            <i class="{{ BLOCK.icon }} text-2xl h-6 leading-6"></i>
            <div class="text-sm text-center">
              {{ builderPrefix + BLOCK.value | lowercase | translate }}
            </div>
          </div>
        </div>

        <!-- STYLE -->
        <div *ngSwitchCase="'STYLE'" class="flex flex-col gap-3">
          <div class="flex justify-between items-center">
            <span class="text-sm text-gray-500">
              {{ builderPrefix + "text-color" | translate }}</span
            >
            <div
              class="w-[164px] rounded-lg border px-3 py-2 flex items-center gap-2"
            >
              <p-colorPicker
                appendTo="body"
                [disabled]="elementSelected.scope !== 'LAYER'"
                [(ngModel)]="layers[elementSelected.layerIndex].textColor"
              ></p-colorPicker>
              <span class="text-sm uppercase">{{
                layers[elementSelected.layerIndex].textColor
              }}</span>
            </div>
          </div>
          <div class="flex justify-between items-center">
            <span class="text-sm text-gray-500">{{
              builderPrefix + "background-color" | translate
            }}</span>
            <div
              class="w-[164px] rounded-lg border px-3 py-2 flex items-center gap-2"
            >
              <p-colorPicker
                appendTo="body"
                [disabled]="elementSelected.scope !== 'LAYER'"
                [(ngModel)]="layers[elementSelected.layerIndex].bgColor"
              ></p-colorPicker>
              <span class="text-sm uppercase">{{
                layers[elementSelected.layerIndex].bgColor
              }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- LAYER -->
    <div>
      <h2 class="font-semibold py-4 px-5 border-t">
        {{ builderPrefix + "layer" | translate }}
      </h2>

      <div class="px-2">
        <p-tree
          [value]="treeLayers"
          selectionMode="single"
          [(selection)]="selection"
          (selectionChange)="onSelectionLayer($event)"
          class="w-full md:w-30rem"
        >
          <ng-template pTemplate="togglericon" let-implicit>
            <i
              class="{{ SoctripIcons.CHEVRON_RIGHT }}"
              [ngClass]="implicit ? 'rotate-90' : ''"
            ></i>
          </ng-template>
          <ng-template let-node pTemplate="default">
            <span *ngIf="node.children && node.children.length > 0">
              {{
                node.label === "Layer"
                  ? (builderPrefix + node.label | lowercase | translate)
                  : node.label
              }}
            </span>
            <span *ngIf="!node.children || node.children.length === 0">
              {{
                node.label
                  ? (builderPrefix + node.label.replaceAll(" ", "_")
                    | lowercase
                    | translate)
                  : "--"
              }}
            </span>
          </ng-template>
        </p-tree>
      </div>
    </div>
  </div>
</div>
